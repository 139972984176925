import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const post = data.markdownRemark

  return (
    <Layout>
      {/* 通过向<SEO> 组件传入 props，可以实时更改博文的元数据。 */}
      {/* 在这种情况下，将使用博客标题 title 和 excerpt（如果存在于博客帖子markdown文件中） */}
      {/* 代替 gatsby-config.js 文件中默认 siteMetadata` 属性。 */}
      {/* https://www.gatsbyjs.cn/docs/add-seo-component/ */}
      {/* https://github.com/nfl/react-helmet#example */}
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <Helmet
        htmlAttributes={{ lang: "en" }}
        meta={[{ name: "description", content: siteDescription }]}
        title={siteTitle}
      />
      <article
        style={{
          // border: `1px solid black`,
          borderRadius: `3px`,
          paddingTop: '3.5rem',
          marginBottom: `0.5rem`,
          // background: `white`,
          fontSize: `1rem`,
        }}
      >
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        update(formatString: "MMMM DD, YYYY")
        url
      }
    }
  }
`
